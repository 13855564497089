<template>
    <div>
        <payroll-confirmation v-if="showConfirmation()"></payroll-confirmation>
        <payroll-stage-three v-else-if="showStepThree()"></payroll-stage-three>
        <payroll-stage-two v-else-if="showStepTwo()"></payroll-stage-two>
        <payroll-stage-one v-else-if="showStepOne()"></payroll-stage-one>
        <payroll-select-cover v-else-if="showSelectCover()"></payroll-select-cover>
        <payroll-get-started v-else></payroll-get-started>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from './../helpers/bus';

    import PayrollGetStarted from './pages/get-started/get-started';
    import PayrollSelectCover from './pages/select-cover/select-cover';
    import PayrollStageOne from './pages/stage-one/stage-one';
    import PayrollStageTwo from './pages/stage-two/stage-two';
    import PayrollStageThree from './pages/stage-three/stage-three';
    import PayrollConfirmation from './pages/confirmation/confirmation';

    export default {
        props:[],
        components:{
            PayrollGetStarted,
            PayrollSelectCover,
            PayrollStageOne,
            PayrollStageTwo,
            PayrollStageThree,
            PayrollConfirmation
        },
        computed: {
            ...mapState(
                [ 'stages', 'application' ],
            ),
            show_select_cover: function(){
                let vm = this;
                let uri = window.location.search.substring(1);
                let params = new URLSearchParams(uri);

                if( params.get("stage") == 'select-cover' && vm.stages['select-cover'] == true ) {
                    return true;
                } else {
                    return false;
                }
            },
        },
        created() {
            let vm = this;
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);

            let api_url = '/wp-json/acf/v3/options/option';

            axios.get(api_url)
                .then(response => {
                    if( response.status == '200' ) {
                        vm.restart_mode = response.data.acf.restart;
                    }
                    if( params.get("restart") && vm.restart_mode === true || !params.get("stage") && vm.stages['confirmation'] == true ) {
                        vm.$store.dispatch( 'restartApplication' );
                    }
                })
                .catch( error => { });

        },
        mounted() {
            let vm = this;
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);

            //Cheap way to make this work
            EventBus.$on('updateStageURL', function(data) {
                location.reload();
            });

            // if( params.get("stage") != 'thank-you' ) {
            //     jQuery('.application-header .restart').hide();
            // }

            window.onpopstate = function(event) {
                location.reload();
            };
        },
        methods: {
            showGetStarted: function(){
                let vm = this;
                let uri = window.location.search.substring(1);
                let params = new URLSearchParams(uri);

                if( !params.get("stage") ) {
                    return true;
                } else {
                    return false;
                }
            },
            showSelectCover: function(){
                let vm = this;
                let uri = window.location.search.substring(1);
                let params = new URLSearchParams(uri);

                if( vm.stages['confirmation'] == true ) {
                        history.pushState(null, 'Complete', '/?stage=application-complete');
                        EventBus.$emit('updateStageURL');
                } else if( params.get("stage") == 'select-cover' && vm.stages['select-cover'] == true ) {
                    return true;
                } else {
                    return false;
                }
            },
            showStepOne: function(){
                let vm = this;
                let uri = window.location.search.substring(1);
                let params = new URLSearchParams(uri);

                if( params.get("stage") == 'step-1' ) {
                    if( vm.stages['confirmation'] == true ) {
                        history.pushState(null, 'Complete', '/?stage=application-complete');
                        EventBus.$emit('updateStageURL');
                    } else if( vm.stages['step-1'] == true ) {
                        return true;
                    } else if( vm.stages['select-cover'] == true ) {
                        history.pushState(null, 'Select Cover', '/?stage=select-cover');
                        EventBus.$emit('updateStageURL');
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            showStepTwo: function(){
                let vm = this;
                let uri = window.location.search.substring(1);
                let params = new URLSearchParams(uri);

                if( params.get("stage") == 'step-2' ) {
                    if( vm.stages['confirmation'] == true ) {
                        history.pushState(null, 'Complete', '/?stage=application-complete');
                        EventBus.$emit('updateStageURL');
                    } else if( vm.stages['step-2'] == true ) {
                        return true;
                    } else if( vm.stages['step-1'] == true ) {
                        history.pushState(null, 'Step 1', '/?stage=step-1');
                        EventBus.$emit('updateStageURL');
                    } else if( vm.stages['select-cover'] == true ) {
                        history.pushState(null, 'Select Cover', '/?stage=select-cover');
                        EventBus.$emit('updateStageURL');
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            showStepThree: function(){
                let vm = this;
                let uri = window.location.search.substring(1);
                let params = new URLSearchParams(uri);

                if( params.get("stage") == 'step-3' ) {

                    if( vm.stages['confirmation'] == true ) {
                        history.pushState(null, 'Complete', '/?stage=application-complete');
                        EventBus.$emit('updateStageURL');
                    } else if( vm.stages['step-3'] == true ) {
                        return true;
                    } else if( vm.stages['step-2'] == true ) {
                        history.pushState(null, 'Step 2', '/?stage=step-2');
                        EventBus.$emit('updateStageURL');
                    } else if( vm.stages['step-1'] == true ) {
                        history.pushState(null, 'Step 1', '/?stage=step-1');
                        EventBus.$emit('updateStageURL');
                    } else if( vm.stages['select-cover'] == true ) {
                        history.pushState(null, 'Select Cover', '/?stage=select-cover');
                        EventBus.$emit('updateStageURL');
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            showConfirmation: function(){
                let vm = this;
                let uri = window.location.search.substring(1);
                let params = new URLSearchParams(uri);

                if( params.get("stage") == 'thank-you' ) {
                    if( vm.stages['confirmation'] == true ) {
                        //jQuery('.application-header .restart').show();
                        return true;
                    } else if( vm.stages['step-3'] == true ) {
                        history.pushState(null, 'Step 2', '/?stage=step-2');
                        EventBus.$emit('updateStageURL');
                    } else if( vm.stages['step-2'] == true ) {
                        history.pushState(null, 'Step 2', '/?stage=step-2');
                        EventBus.$emit('updateStageURL');
                    } else if( vm.stages['step-1'] == true ) {
                        history.pushState(null, 'Step 1', '/?stage=step-1');
                        EventBus.$emit('updateStageURL');
                    } else if( vm.stages['select-cover'] == true ) {
                        history.pushState(null, 'Select Cover', '/?stage=select-cover');
                        EventBus.$emit('updateStageURL');
                    } else {
                        return false;
                    }
                } else {
                    if( vm.stages['confirmation'] == true ) {
                        history.pushState(null, 'Thank You', '/?stage=thank-you');
                        return true;
                    } else {
                        return false;
                    }
                }
            },
        },
        data () {
            return {
                restart_mode : false
            }
        }
    }
</script>