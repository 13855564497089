<template>
    <thead>
        <tr class="header">
            <td colspan="3" class="al" >Levels of cover</td>
            <td class="tab-level-info tab-level-1 active" data-level="1">Level 1</td>
            <td class="tab-level-info tab-level-2" data-level="2">Level 2</td>
            <td class="tab-level-info tab-level-3" data-level="3">Level 3</td>
            <td class="tab-level-info tab-level-4" data-level="4">Level 4</td>
            <td class="tab-level-info tab-level-5" data-level="5">Level 5</td>
        </tr>
        <tr>
            <td colspan="3" class="al" v-html="price_level_label"></td>
            <td class="tab-level-info tab-level-1 active" data-level="1"><span class="money">&pound;{{ getPriceLevelLabel( 1 ) }}</span></td>
            <td class="tab-level-info tab-level-2 alt" data-level="2"><span class="money">&pound;{{ getPriceLevelLabel( 2 ) }}</span></td>
            <td class="tab-level-info tab-level-3" data-level="3"><span class="money">&pound;{{ getPriceLevelLabel( 3 ) }}</span></td>
            <td class="tab-level-info tab-level-4 alt" data-level="4"><span class="money">&pound;{{ getPriceLevelLabel( 4 ) }}</span></td>
            <td class="tab-level-info tab-level-5" data-level="5"><span class="money">&pound;{{ getPriceLevelLabel( 5 ) }}</span></td>
        </tr>
        <tr>
            <td colspan="3" class="al">Choose a level</td>
            <td class="tab-level-info tab-level-1 active" data-level="1">
                <a class="choice" @click="updateCoverLevel('1')">Choose</a>
            </td>
            <td class="tab-level-info tab-level-2 alt" data-level="2">
                <a class="choice" @click="updateCoverLevel('2')">Choose</a>
            </td>
            <td class="tab-level-info tab-level-3" data-level="3">
                <a class="choice" @click="updateCoverLevel('3')">Choose</a>
            </td>
            <td class="tab-level-info tab-level-4 alt" data-level="4">
                <a class="choice" @click="updateCoverLevel('4')">Choose</a>
            </td>
            <td class="tab-level-info tab-level-5" data-level="5">
                <a class="choice" @click="updateCoverLevel('5')">Choose</a>
            </td>
        </tr>
    </thead>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from './../../../../helpers/bus';

    export default {
        props:[],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            price_level_label: function() {
                let vm = this;
                var label = '';

                if( vm.application.payment_type == 'weekly' ) {
                    label = 'Weekly premium (per adult)';
                } else if( vm.application.payment_type == 'four-weekly' ) {
                    label = 'Four weekly premium (per adult)';
                } else if( vm.application.payment_type == 'monthly' ) {
                    label = 'Monthly premium (per adult)';
                }

                return label;
            }
        },
        created() {   
            let vm = this,
                api_url = '/wp-json/sovereign/v1/get-pricing-levels',
                pricing_levels = null;

            axios.get(api_url)
                .then(response => { 
                    if( response.status == '200' ) {
                        pricing_levels = response.data;
                        vm.$store.dispatch( 'updatePrices', pricing_levels );
                    }
                })
                .catch( error => { });        
        },
        mounted() {
        },
        methods: {
            updateCoverLevel( cover_level ) {
                let vm = this;
                
                vm.$store.dispatch( 'updateCoverLevel', cover_level );
                vm.$store.dispatch( 'validateApplication', [ 'cover_level', cover_level ] );

                vm.$store.dispatch( 'updateStage', [ 'step-1', true ] );

                history.pushState(null, 'Step 1', '/?stage=step-1');
                EventBus.$emit('updateStageURL');
            }
        },
        data () {
            return {
            }
        }
    }
</script>