<template>
    <fieldset class="main-col add-child-form">

        <div class="formInner no-bottom">
            <a class="addChild addBox" :class="{ 'active' : this.editing, 'disabled' : Object.keys(application.additional_policies.children).length >= 4 }" @click="toggleAddForm()" data-cy="add-child-button">Add up to four dependent children aged under 18 for free</a>

            <div class="childForm" :class="{ 'editing' : this.editing, 'hidden' : !this.editing }">

                <div class="warnings premium-tax">
                    <p class="has-i"><i class="fa fa-info"></i>Add up to four dependent children up to the age of 18 who live with you below.</p>

                    <div class="childConsent" v-if="Object.keys(application.additional_policies.children).length < 1">
                        <p>As a parent or legal guardian of the dependent child, you confirm you have the authority to share their personal data on their behalf.</p>
                    </div>
                </div>

				<div class="input-group multiple">
                    <div class="input-group input-title">
                        <label for="childTitle"><sup>*</sup>Title</label>

                        <div class="select-wrapper">
                            <select name="child[title]" v-model="child.title" id="childTitle" @blur="validateTitle()" data-cy="add-child-title">
                                <option disabled value="">Select</option>
                                <option value="Miss">Miss</option>
                                <option value="Master">Master</option>
                            </select>
                        </div>

                        <span class="help-block form-error" v-if="valid_title !== null && valid_title.valid === false" v-html="valid_title.message" data-cy="add-child-title-error"></span>
                    </div>

                    <div class="input-box input-firstname">
                        <label for="childFirstName"><sup>*</sup>First name(s)</label>
                        <input type="text" name="child[firstname]" id="childFirstName" v-model="child.first_name" @blur="validateFirstName()" data-cy="add-child-firstname"/>

                        <span class="help-block form-error" v-if="valid_first_name !== null && valid_first_name.valid === false" v-html="valid_first_name.message" data-cy="add-child-firstname-error"></span>
                    </div>

                    <div class="input-box input-lastname">
                        <label for="childLastName"><sup>*</sup>Surname</label>
                        <input type="text" name="child[lastname]" id="childLastName" v-model="child.last_name" @blur="validateLastName()" data-cy="add-child-lastname"/>

                        <span class="help-block form-error" v-if="valid_last_name !== null && valid_last_name.valid === false" v-html="valid_last_name.message" data-cy="add-child-firstname-error"></span>
                    </div>
                </div>

                <div class="dob-wrap">
                    <label for="childDob"><sup>*</sup>Date of birth</label>
                    <div class="input-group multiple">

                        <div class="select-wrapper sm">
                            <select name="child[date_of_birth][day]" v-model="child.date_of_birth.day" :class="{ 'has-error' : valid_dob.valid === false }" @change="updateDOB('day')" data-cy="add-child-dob-day">
                                <option disabled value="">DD</option>
                                <option v-for="day in 31" :key="day">{{day}}</option>
                            </select>
                        </div>

                        <div class="select-wrapper sm">
                            <select name="child[date_of_birth][month]" v-model="child.date_of_birth.month" :class="{ 'has-error' : valid_dob.valid === false }" @change="updateDOB('month')" data-cy="add-child-dob-month">
                                <option disabled value="">MM</option>
                                <option v-for="month in 12" :key="month">{{month}}</option>
                            </select>
                        </div>

                        <div class="select-wrapper sm">
                            <select name="child[date_of_birth][year]" v-model="child.date_of_birth.year" :class="{ 'has-error' : valid_dob.valid === false }" @change="updateDOB('year')" data-cy="add-child-dob-year">
                                <option disabled value="">YYYY</option>
                                <option v-for="year in dob_year_range" :key="year">{{year}}</option>
                            </select>
                        </div>

                        <span class="help-block form-error" v-if="valid_dob.valid === false" v-html="valid_dob.message" data-cy="add-child-dob-error"></span>
                    </div>
                </div>

                <div class="action-box">
                    <input type="button" value="Remove" class="btn btn-secondary" @click="removeChildApplicant()" data-cy="add-adult-remove-button"/>
                    <input type="button" value="Confirm" class="btn btn--primary" :disabled="!is_valid" @click="addChildApplicant()" data-cy="add-child-confirm-button"/>
				</div>
			</div>
		</div>
	</fieldset>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from '../../../../helpers/bus';

    export default {
        props:[],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            dob_year_range: function() {
                var top_year = moment().format('Y'),
                    bottom_year = moment().subtract(18, 'years').format('Y'),
                    years = [],
                    year = 0;


                for(var i = top_year; i >= bottom_year; i--){
                    years[year] = i;
                    year++;
                }

                return years;
            },
            is_valid: function() {
                let vm = this,
                    valid = true;

                if( vm.valid_title === null || vm.valid_title.valid === false ) valid = false;
                if( vm.valid_first_name === null || vm.valid_first_name.valid === false ) valid = false;
                if( vm.valid_last_name === null || vm.valid_last_name.valid === false ) valid = false;
                if( vm.valid_dob.valid === false || vm.valid_dob.valid === null ) valid = false;

                return valid;
            },
            valid_dob: function() {
                let vm = this,
                    valid_dob = [];

                if( vm.editing_dob.day && vm.editing_dob.month && vm.editing_dob.year ) {
                    valid_dob = vm.checkValidDateOfBirth( vm.child.date_of_birth, 'child' );
                } else {
                    valid_dob.valid = null;
                }

                return valid_dob;
            }
        },
        created() {
        },
        mounted() {
            let vm = this;

            EventBus.$on('addChild', function(data) {
                vm.addChildApplicant();
            });
        },
        methods: {
            addChildApplicant: function(){
                let vm = this;

                if( vm.is_valid ) {
                    vm.$store.dispatch( 'addChildApplicant', vm.child );
                    vm.editing = false;
                    vm.child = {
                        title: '',
                        first_name: null,
                        last_name: null,
                        date_of_birth: {
                            day: '',
                            month: '',
                            year: '',
                        }
                    };
                    vm.editing_dob = {
                        day: false,
                        month: false,
                        year: false
                    };
                    jQuery(window).scrollTop(jQuery('.addChild.addBox').offset().top - (jQuery('#site-header').outerHeight() + 30) );
                }
            },
            toggleAddForm: function(){
                let vm = this;

                if( Object.keys(vm.application.additional_policies.children).length < 4 ) {
                    vm.editing = !vm.editing;
                }
            },
            removeChildApplicant: function(){
                let vm = this;
                vm.editing = false;
                vm.child = {
                    title: null,
                    first_name: null,
                    last_name: null,
                    date_of_birth: {
                        day: null,
                        month: null,
                        year: null,
                    }
                };
                vm.editing_dob = {
                    day: false,
                    month: false,
                    year: false
                };
            },
            updateDOB( type ) {
                let vm = this;
                vm.editing_dob[type] = true;
            },
            validateTitle() {
                let vm = this;
                if( vm.child.title !== null ) {
                    vm.child.title = vm.sanitizeValue(vm.child.title);
                }
                vm.valid_title = vm.checkValidTitle( vm.child.title, 'child' );
            },
            validateFirstName() {
                let vm = this;
                if( vm.child.first_name !== null ) {
                    vm.child.first_name = vm.sanitizeValue(vm.child.first_name);
                }
                vm.valid_first_name = vm.checkValidFirstName( vm.child.first_name, 'child' );
            },
            validateLastName() {
                let vm = this;
                if( vm.child.last_name !== null ) {
                    vm.child.last_name = vm.sanitizeValue(vm.child.last_name);
                }
                vm.valid_last_name = vm.checkValidLastName( vm.child.last_name, 'child' );
            }
        },
        data () {
            return {
                child: {
                    title: '',
                    first_name: null,
                    last_name: null,
                    date_of_birth: {
                        day: '',
                        month: '',
                        year: '',
                    }
                },
                editing: false,
                editing_dob: {
                    day: false,
                    month: false,
                    year: false
                },
                valid_title: null,
                valid_first_name: null,
                valid_last_name: null
            }
        }
    }
</script>
