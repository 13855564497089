import "@babel/polyfill";
require('es6-promise').polyfill();
import axios from "axios";

import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';

import Cookies from 'js-cookie';

window.axios = axios;

window.Vue = Vue;
window.Vuex = Vuex;
window.moment = moment;

require("./vue/vue.js");

//jQuery wrapper
(function($) {

	// Document ready
	$(document).ready(function(){

 		// Javascript is enabled
 		$('html.ie').removeClass('no-js').addClass('js');
 		$('#nojs-notice').remove();

 		// IE classes
 		if (/*@cc_on!@*/false) {
 			document.documentElement.className+=' ie' + document.documentMode;
 		}

 		__init();

 		$(window).resize(function(){
 			__resizeWindowEvents();
 		});

 		$(window).scroll(function(){
 			__onScrollEvents();
 		});

 		window.addEventListener('orientationchange',function(){
 			__floatingSide();
 		});

	});

	var __init = function(){
		__applicationHelpers();
		__applicationSummary();

		__basicTableWrap();
		__floatingSide();
	}


	var __basicTableWrap = function(){
		var table = $('.full-width-table');
		table.wrap('<div class="example-Wrap" />');
	}

	var __applicationSummary = function(){
		if($(window).width() >= 1024){

			$(window).scroll(function(e){
				__floatingSide();
			});

			$(window).resize(function(e){
				__floatingSide();
			})
		}

	}

	var __floatingSide = function(){

			if($('.floating-container').length > 0){

			var winHeight = $('body').height();
			var footerHeight = $('#site-footer').outerHeight();
			var boxHeight = $('.floating-container').outerHeight();
			var noteHeight = ($('note').length > 0) ? $('.note').outerHeight(true) : 25;
			var bottomOfHeader = $('#site-header').height();
			var floatingTop = $('.floating-container').offset().top;
			var initFloatingTop = 404;

			var stopPoint = (winHeight) - (footerHeight + boxHeight);

			if($(window).width() >= 1024){

				if( $(window).scrollTop() >= stopPoint){

					$('.floating-container').css( { position: 'absolute', top: 'auto', bottom: noteHeight });

				} else if( $(window).scrollTop() >= (initFloatingTop - bottomOfHeader)  ){

					$('.floating-container').css( { position: 'fixed', top: 113 });

				} else {

					$('.floating-container').css( { position: 'absolute', top: 0 });

				}

			} else {

				$('.floating-container').css( { position: 'static', top: 'auto', bottom: 'auto' });

			}
		}

	}

	var __applicationHelpers = function(){

		__mobileLevelChanger();
		__tableColumnHover();

		$('body').on( 'click', '.coverExpand', function(e){
			$(this).parent().parent().parent().next().toggleClass('active');
		});

		$('body').on( 'hover', '.pop-out-link', function(e){
			$(this).next().toggleClass('active');
		},function(e){
			$(this).next().toggleClass('active');
		})

		$('body').on('click', '.expander-box.can-expand h2', function(e){
			e.preventDefault();
			$(this).children('a').toggleClass('active');
			$(this).next().toggleClass('active');
		});

	}

	var __tableColumnHover = function(){

		$('.tab-level-info').hover(function(){
			var id = $(this).attr('data-level');

			$('.tab-level-info').removeClass('hovering');
			$('.tab-level-'+id).addClass('hovering');

		},function(){

			$('.tab-level-info').removeClass('hovering');

		});
	}

	var __mobileLevelChanger = function(){

		$('body').on( 'click', '.tab-level-link', function(e){

			e.preventDefault();
			var level = $(this).attr('data-level');
			$('.tab-level-info').removeClass('active');
			$('.tab-level-'+level).addClass('active');
			$('.tab-level-link').removeClass('active');

			$('.tab-level-link').each(function(i){
				if($(this).attr('data-level') == level){
					$(this).addClass('active');
				}
			});

		});
	}

	var __onScrollEvents = function(){
		__fixedNavigation();
	}

	var __resizeWindowEvents = function(){
		__floatingSide();
	}

	var __fixedNavigation = function(){
		if( $(window).scrollTop() > 0){
			$('#site-header').addClass('fixed');
		} else {
			$('#site-header').removeClass('fixed');
		}
	}

	if($('.js-message-bar').length) {
		$('.js-message-bar__dismiss').on('click', function() {
			$(this).parent().hide();
			Cookies.set('hide_message_bar', true)
		});
	};

	String.prototype.decodeHTML = function() { return $("<div>", {html: "" + this}).html(); };

	// End jQuery wrapper
})(jQuery);

String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};
