<template>
    <div class="main-col">
        <div class="formInner payroll-summary">
            <div class="expander-box can-expand">
                <h2>Your Payroll Deduction Details<a class="expand arrowDown active">Expand</a></h2>
                <div class="content active">
                    <p><strong>Payment frequency:</strong> {{ payment_frequency }}</p>
                    <p><strong>Payroll/Employee reference number:</strong> <span data-cy="summary-payroll-number">{{ application.payroll_number }}</span></p>
                    <p><strong>Employer name:</strong> <span data-cy="summary-employer-name">{{ application.employer_name }}</span></p>
                    <p v-if="application.location !== null && application.location !== ''"><strong>Location:</strong> <span data-cy="summary-employer-location">{{ application.location }}</span></p>
                    <a class="btn btn--primary" @click="returnToStepTwo()">Edit</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from './../../../../helpers/bus';

    export default {
        props:[],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            payment_frequency: function() {
                let vm = this;
                var label = '';

                if( vm.application.payment_type == 'weekly' ) {
                    label = 'Weekly';
                } else if( vm.application.payment_type == 'four-weekly' ) {
                    label = 'Every four weeks';
                } else if( vm.application.payment_type == 'monthly' ) {
                    label = 'Monthly';
                }

                return label;
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {
            returnToStepTwo( ) {
                let vm = this;
                vm.$store.dispatch( 'updateStage', [ 'step-2', true ] );

                history.pushState(null, 'Step 2', '/?stage=step-2');
                EventBus.$emit('updateStageURL');
            }
        },
        data () {
            return {
            }
        }
    }
</script>