<template>
    <div class="main-col">
        <div class="formInner primary-summary">
            <div class="expander-box can-expand">
                <h2>Your Cover<a class="expand arrowDown active">Expand</a></h2>
                <div class="content active">
                    <p><strong v-html="cleanName( application.title, application.first_name, application.last_name )" data-cy="summary-name"></strong></p>
                    <p><strong>Cover level:</strong> Level <span data-cy="summary-level">{{ application.cover_level }}</span></p>
                    <p><strong>{{ getPremiumLabel() }}:</strong> <span data-cy="summary-cover-price">&pound;{{ getCurrentPrice(application.cover_level) }}</span></p>
                    <p><strong>Date of birth:</strong> <span data-cy="summary-dob">{{ date_of_birth }}</span></p>
                    <p><strong>Phone:</strong> <span data-cy="summary-phone">{{ application.phone_number }}</span></p>
                    <p><strong>Email:</strong>  <span data-cy="summary-email">{{ application.email_address }}</span></p>
                    <p><strong>Address:</strong> <span data-cy="summary-address">{{ main_address }}</span></p>
                    <p><strong>Postcode:</strong> <span data-cy="summary-postcode">{{ application.address.postcode }}</span></p>
                    <br>
                    <p v-if="application.recommended_policy_no !== null && application.recommended_policy_no !== ''"><strong>Introducer’s policy number:</strong> <span data-cy="summary-promo-code">{{ application.recommended_policy_no }}</span></p>
                    <p v-else><strong>Introducer’s policy number:</strong> n/a</p>
                    <p v-if="application.offer_code !== null && application.offer_code !== ''"><strong>Offer code:</strong>  <span data-cy="summary-offer-code">{{ application.offer_code }}</span></p>
                    <p v-else><strong>Offer code:</strong> n/a</p>
                    <p><strong>How did you hear about Sovereign Health Care?:</strong> <span data-cy="summary-referrer">{{ application.where_did_you_hear }}</span></p>

                    <a class="btn btn--primary" @click="returnToStepOne()" data-cy="summary-edit-primary-button">Edit</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from './../../../../helpers/bus';

    export default {
        props:[],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            date_of_birth: function(){ 
                let vm = this;
                var dob = "";

                dob = vm.application.date_of_birth.day + "/" + vm.application.date_of_birth.month + "/" + vm.application.date_of_birth.year;

                return dob;
            },
            main_address: function(){ 
                let vm = this;
                var address = "";

                if( vm.application.address.line_1 !== null && vm.application.address.line_1 !== '' ) {
                    address = vm.application.address.line_1;
                }
                if( vm.application.address.line_2 !== null && vm.application.address.line_2 !== '' ) {
                    address += ', ' + vm.application.address.line_2;
                }
                if( vm.application.address.line_3 !== null && vm.application.address.line_3 !== '' ) {
                    address += ', ' + vm.application.address.line_3;
                }
                if( vm.application.address.line_4 !== null && vm.application.address.line_4 !== '' ) {
                    address += ', ' + vm.application.address.line_4;
                }

                return address;
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {
            returnToStepOne( ) {
                let vm = this;
                vm.$store.dispatch( 'updateStage', [ 'step-1', true ] );

                history.pushState(null, 'Step 1', '/?stage=step-1');
                EventBus.$emit('updateStageURL');
            }
        },
        data () {
            return {
            }
        }
    }
</script>