<template>
    <div class="main-col">
        <div class="formInner children-summary">
            <div class="expander-box can-expand">
                <h2>Your Children's Cover<a class="expand arrowDown active">Expand</a></h2>
                <div class="content active" v-if="Object.keys(application.additional_policies.children).length > 0">
                    <div class="child" v-for="(child, child_key) in application.additional_policies.children" :key="'child_'+child_key" :data-cy="'summary-child-'+ child_key">
                        <p><strong v-html="cleanName( child.title, child.first_name, child.last_name )"></strong><p>
                        <p><strong>Date of birth:</strong> {{ getDateOfBirth(child.date_of_birth) }}</p>
                    </div>
                    <a class="btn btn--primary" @click="returnToStepOne('edit')">Edit</a>
                </div>
                <div class="content active" v-else data-cy="summary-children">
                    <div class="no-content"><p><i class="fa fa-info"></i>No children added</p></div>
                    <a class="btn btn--primary" @click="returnToStepOne('add')">Edit</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from './../../../../helpers/bus';

    export default {
        props:[],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
        },
        created() {
        },
        mounted() {
        },
        methods: {
            getDateOfBirth: function( date_of_birth ){ 
                var dob = "";
                dob = date_of_birth.day + "/" + date_of_birth.month + "/" + date_of_birth.year;
                return dob;
            },
            returnToStepOne( where ) {
                let vm = this;
                vm.$store.dispatch( 'updateStage', [ 'step-1', true ] );

                if( where && where == 'add' ) {
                    history.pushState(null, 'Step 1', '/?stage=step-1#add-children');
                } else if( where && where == 'edit' ) {
                    history.pushState(null, 'Step 1', '/?stage=step-1#edit-children');
                } else {
                    history.pushState(null, 'Step 1', '/?stage=step-1');
                }

                EventBus.$emit('updateStageURL');
            }
        },
        data () {
            return {
            }
        }
    }
</script>