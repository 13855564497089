<template>
    <div class="main-col">
        <div class="formInner adults-summary">
            <div class="expander-box can-expand">
                <h2>Your Partner's/Family Member's Cover<a class="expand arrowDown active">Expand</a></h2>
                <div class="content active" v-if="Object.keys(application.additional_policies.adults).length > 0">
                    <div class="adult" v-for="(adult, adult_key) in application.additional_policies.adults" :key="'adult_'+adult_key" :data-cy="'summary-adult-'+ adult_key">
                        <p><strong v-html="cleanName( adult.title, adult.first_name, adult.last_name )"></strong><p>
                        <p><strong>Cover level:</strong> Level {{ adult.cover_level }}</p>
                        <p><strong>{{ getPremiumLabel() }}:</strong> &pound;{{ getCurrentPrice(adult.cover_level) }}</p>
                        <p><strong>Date of birth:</strong> {{ getDateOfBirth(adult.date_of_birth) }}</p>
                        <p><strong>Email:</strong> {{ adult.email }}</p>
                        <p><strong>Address:</strong> {{ getAdultAddress( adult ) }}</p>
                        <p><strong>Postcode:</strong> {{ adult.postcode }}</p>
                    </div>
                    <a class="btn btn--primary" @click="returnToStepOne('edit')">Edit</a>
                </div>
                <div class="content active" v-else data-cy="summary-adult">
                    <div class="no-content"><p><i class="fa fa-info"></i>No adults added</p></div>
                    <a class="btn btn--primary" @click="returnToStepOne('add')">Edit</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from './../../../../helpers/bus';

    export default {
        props:[],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
        },
        created() {
        },
        mounted() {
        },
        methods: {
            getDateOfBirth: function( date_of_birth ){ 
                var dob = "";

                dob = date_of_birth.day + "/" + date_of_birth.month + "/" + date_of_birth.year;

                return dob;
            },
            getAdultAddress: function( adult ){ 
                let vm = this;
                var address = "";

                if( adult.address_line_1 !== null && adult.address_line_1 !== '' ) {
                    address = adult.address_line_1;
                }
                if( adult.address_line_2 !== null && adult.address_line_2 !== '' ) {
                    address += ', ' + adult.address_line_2;
                }
                if( adult.address_line_3 !== null && adult.address_line_3 !== '' ) {
                    address += ', ' + adult.address_line_3;
                }
                if( adult.address_line_4 !== null && adult.address_line_4 !== '' ) {
                    address += ', ' + adult.address_line_4;
                }

                return address;
            },
            returnToStepOne( where ) {
                let vm = this;
                vm.$store.dispatch( 'updateStage', [ 'step-1', true ] );

                if( where && where == 'add' ) {
                    history.pushState(null, 'Step 1', '/?stage=step-1#add-adults');
                } else if( where && where == 'edit' ) {
                    history.pushState(null, 'Step 1', '/?stage=step-1#edit-adults');
                } else {
                    history.pushState(null, 'Step 1', '/?stage=step-1');
                }

                EventBus.$emit('updateStageURL');
            }
        },
        data () {
            return {
            }
        }
    }
</script>