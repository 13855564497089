<template>
    <div>
        <h4>Your cover</h4>
        <ul>
            <li>
                <div class="your-level">
                    <div class="sumCol">
                        <p class="name" v-html="cleanName(application.title, application.first_name, application.last_name)"></p>
                        <p>Level <b class="number" v-html="application.cover_level"></b></p>
                    </div>
                    <div class="sumCol">
                        <p v-if="!is_step_1"><a @click="returnToStepOne()" class="edit">Edit</a></p>
                        <p><span class="amount">&pound;<b class="value" v-html="getCurrentPrice(application.cover_level)"></b> <em v-html="price_level_label_simple"></em></span></p>
                    </div>
                </div>
            </li>
        </ul>

        <template v-if="Object.keys(application.additional_policies.adults).length > 0">
            <div class="part-summary">
                <h4>Your family member&rsquo;s cover</h4>
                <ul>
                    <li v-for="(adult, adult_key) in application.additional_policies.adults" :key="'adult_'+adult_key">
                        <div class="adult-summary">
                            <div class="sumCol">
                                <p class="name" v-html="cleanName(adult.title, adult.first_name, adult.last_name)"></p>
                                <p>Level <b class="number" v-html="adult.cover_level"></b></p>
                            </div>
                            <div class="sumCol">
                                <p v-if="!is_step_1"><a @click="returnToStepOne('adults')" class="edit">Edit</a></p>
                                <p><span class="amount">&pound;<b class="value" v-html="getCurrentPrice(adult.cover_level)"></b> <em v-html="price_level_label_simple"></em></span></p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </template>

        <template v-if="Object.keys(application.additional_policies.children).length > 0">
            <h4>Your children&rsquo;s cover</h4>
            <div class="child-summary">
                <ul>
                    <template  v-for="(child, child_key) in application.additional_policies.children">
                        <li v-if="child !== null" :key="'child_'+child_key">
                            <div class="">
                                <div class="sumCol">
                                    <p class="name" v-html="cleanName(child.title, child.first_name, child.last_name)"></p>
                                </div>
                                <div class="sumCol">
                                    <p v-if="!is_step_1"><a @click="returnToStepOne('children')" class="edit">Edit</a></p>
                                    <p><span class="amount">FREE</span></p>
                                </div>
                            </div>
                        </li> 
                    </template>
                </ul>
            </div>
        </template>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from './../../helpers/bus';

    export default {
        props:[],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            is_step_1: function(){
                let vm = this;
                let uri = window.location.search.substring(1); 
                let params = new URLSearchParams(uri);

                if( params.get("stage") == 'step-1' ) {
                    return true;
                } else {
                    return false;
                }
            },
            is_step_2: function(){
                let vm = this;
                let uri = window.location.search.substring(1); 
                let params = new URLSearchParams(uri);

                if( params.get("stage") == 'step-2' ) {
                    return true;
                } else {
                    return false;
                }
            },
            is_step_3: function(){
                let vm = this;
                let uri = window.location.search.substring(1); 
                let params = new URLSearchParams(uri);

                if( params.get("stage") == 'step-3' ) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {
            returnToStepOne( where ) {
                let vm = this;
                vm.$store.dispatch( 'updateStage', [ 'step-1', true ] );

                console.log(where);

                if( where && where == 'adults' ) {
                    history.pushState(null, 'Step 1', '/?stage=step-1#edit-adults');
                } else if( where && where == 'children' ) {
                    history.pushState(null, 'Step 1', '/?stage=step-1#edit-children');
                } else {
                    history.pushState(null, 'Step 1', '/?stage=step-1');
                }

                EventBus.$emit('updateStageURL');
            }
        },
        data () {
            return {
            }
        }
    }
</script>