import VueAnalytics from 'vue-analytics'
import Select2 from 'v-select2-component';

Vue.component('Select2', Select2);

import store from './helpers/store'

window.templatePath = '/app/themes/sovereign/dist/';
window.imagesPath = '/app/themes/sovereign/dist/images/';
window.Event = new Vue();

import PayrollIndex from './views/index.vue';

import Mixins from './mixins/application';

Vue.mixin(Mixins); 

Vue.use(VueAnalytics, {
    id: 'UA-16236202-5'
});

(function($) {
    if( $('#payroll-app').length > 0 ){
        window.myPage = new Vue({
            el: '#payroll-app',
            components: {
                PayrollIndex
            },
            store
        });
    }
})(jQuery);