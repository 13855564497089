<template>
    <div class="main-col primary-form">
        <fieldset class="main-details-form">
            <div class="application-stage__level-selector">
                <div class="level-selector-wrap">
                    <p class="coverHead">Your cover level</p>
                    <div class="wrap-x-slide">
                        <div class="level-selector">
                            <template v-for="level in [1, 2, 3, 4 ,5]">
                                <label :key="'your-level-'+level" :class="{ 'active' : application.cover_level == level }" @click="updateCoverLevel(level)" >
                                    <span class="level">Level {{ level }}</span>
                                    <span class="prices-wrap">
                                        <span class="amount">&pound;{{ getPriceLevelLabel( level ) }}</span>
                                        <span class="pp" v-html="price_level_label_per_adult"></span>
                                    </span>
                                    <span>
                                        <input v-model="application.cover_level" :value="level" data-validation="required" data-validation-error-msg="Please select a level of cover" class="level-select your-level-select" type="radio" name="main[level]" />
                                        <div class="checked"></div>
                                    </span>
                                </label>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="formInner premium-tax">
                <p class="has-i"><i class="fa fa-info"></i>Premiums include insurance premium tax (IPT).</p>
            </div>

            <div class="formInner">
                <span class="help-block form-error" v-if="errors.cover_level.valid === false" v-html="errors.cover_level.message"></span>

                <legend>Your Details</legend>

                <div class="input-group multiple">
                    <div class="input-group input-title">
                        <label for="mainTitle"><sup>*</sup>Title</label>

                        <div class="select-wrapper">
                            <select v-model="applicant_title" id="mainTitle" name="main[title]" :class="{ 'has-error' : errors.title.valid === false}" @blur="validateTitle()" data-cy="main-title">
                                <option disabled value="">Select</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Miss">Miss</option>
                                <option value="Ms">Ms</option>
                                <option value="Dr">Dr</option>
                                <option value="n/a">Prefer not to say</option>
                            </select>
                        </div>

                        <span class="help-block form-error" v-if="errors.title.valid === false" v-html="errors.title.message" data-cy="main-title-error"></span>
                    </div>

                    <div class="input-group input-firstname">
                        <label for="mainFirst"><sup>*</sup>First name(s)</label>
                        <input v-model="applicant_first_name" type="text" name="main[first_name]" id="mainFirst" :class="{ 'has-error' : errors.first_name.valid === false}" @blur="validateFirstName()" data-cy="main-firstname"/>

                        <span class="help-block form-error" v-if="errors.first_name.valid === false" v-html="errors.first_name.message" data-cy="main-firstname-error"></span>
                    </div>

                    <div class="input-group input-lastname">
                        <label for="mainLast"><sup>*</sup>Surname</label>
                        <input v-model="applicant_last_name" type="text"  name="main[last_name]" id="mainLast" :class="{ 'has-error' : errors.last_name.valid === false}" @blur="validateLastName()" data-cy="main-lastname"/>

                        <span class="help-block form-error" v-if="errors.last_name.valid === false" v-html="errors.last_name.message" data-cy="main-lastname-error"></span>
                    </div>
                </div>

                <div class="dob-wrap">
                    <label for="mainDob"><sup>*</sup>Date of birth</label>
                    <div class="input-group multiple">
                        <div class="select-wrapper sm">
                            <select v-model="applicant_dob_day" id="mainDobDay" name="main[date_of_birth][day]" :class="{ 'has-error' : errors.date_of_birth.valid === false }" @change="updateDOB('day')" data-cy="main-dob-day">
                                <option disabled value="">DD</option>
                                <option v-for="day in 31" :key="day" :value="day">{{day}}</option>
                            </select>
                        </div>

                        <div class="select-wrapper sm">
                            <select v-model="applicant_dob_month" id="mainDobMonth" name="main[date_of_birth][month]" :class="{ 'has-error' : errors.date_of_birth.valid === false }" @change="updateDOB('month')" data-cy="main-dob-month">
                                <option disabled value="">MM</option>
                                <option v-for="month in 12" :key="month" :value="month">{{month}}</option>
                            </select>
                        </div>

                        <div class="select-wrapper sm">
                            <select v-model="applicant_dob_year" id="mainDobYear" name="main[date_of_birth][year]" :class="{ 'has-error' : errors.date_of_birth.valid === false }" @change="updateDOB('year')" data-cy="main-dob-year">
                                <option disabled value="">YYYY</option>
                                <option v-for="year in dob_year_range" :key="year" :value="year">{{year}}</option>
                            </select>
                        </div>

                        <span class="help-block form-error" v-if="errors.date_of_birth.valid === false" v-html="errors.date_of_birth.message"  data-cy="main-dob-error"></span>
                    </div>
                </div>
            </div>
        </fieldset>

        <fieldset class="contact-details-form">
            <div class="formInner">
                <legend>Contact Details</legend>

                <div class="input-group">
                    <label for="mainEmail"><sup>*</sup>Email</label>
                    <input v-model="applicant_email_address" type="text"  name="main[email_address]" id="mainEmail" :class="{ 'has-error' : errors.email_address.valid === false}" @blur="validateEmailAddress()" data-cy="main-email"/>

                    <span class="help-block form-error" v-if="errors.email_address.valid === false" v-html="errors.email_address.message" data-cy="main-email-error"></span>
                </div>

                <div class="input-group">
                    <label for="mainPhone"><sup>*</sup>Contact phone number</label>
                    <input v-model="applicant_phone_number" type="text" name="main[phone_number]" id="mainPhone" :class="{ 'has-error' : errors.phone_number.valid === false}" @blur="validatePhoneNumber()" data-cy="main-phone"/>

                    <span class="help-block form-error" v-if="errors.phone_number.valid === false" v-html="errors.phone_number.message" data-cy="main-phone-error"></span>
                </div>

                <div class="postcode-wrap">
                    <label for="searchPostcode"><sup>*</sup>Postcode</label>

                    <div class="input-group multiple">
                        <input v-model="search_postcode" type="text" id="searchPostcode" :class="{ 'has-error' : valid_search_postcode !== null && valid_search_postcode.valid === false }" @blur="cleanAddress()" data-cy="main-postcode-search"/>

                        <div class="housePicker">
                            <input type="button" value="Find Address" class="btn btn--primary" :disabled="search_postcode == null || search_postcode == ''" @click="lookupAddress()" data-cy="main-postcode-search-button"/>
                        </div>

                        <a class="reveal-address reveal-address--main" @click="revealAddress()">Enter your address manually</a>
                    </div>

                    <span class="help-block form-error" v-if="valid_search_postcode !== null && valid_search_postcode.valid === false" v-html="valid_search_postcode.message" data-cy="main-postcode-search-error"></span>
                </div>

                <div class="hidden" id="mainAddressHide">
                    <label for="addressSelect">Select your address</label>

                    <div class="select-wrapper">
                        <select name="addressSelect" id="mainAddressSelect" @change="updateAddress()" data-cy="main-address-select">
                            <option></option>
                        </select>
                    </div>
                </div>

                <div :class="{ 'hidden' : applicant_address_line_1 === null }" id="mainAddressLines">
                    <div class="input-group">
                        <label for="mainAddressLine1"><sup>*</sup>Address line 1</label>
                        <input v-model="applicant_address_line_1" type="text" name="main[addressLine1]" id="mainAddressLine1" :class="{ 'has-error' : errors.address_line_1.valid === false}" @blur="validateAddressLine1()" data-cy="main-address-1"/>

                        <span class="help-block form-error" v-if="errors.address_line_1.valid === false" v-html="errors.address_line_1.message" data-cy="main-address-1-error"></span>
                    </div>

                    <div class="input-group">
                        <label for="mainAddressLine2"><sup>*</sup>Address line 2</label>
                        <input v-model="applicant_address_line_2" type="text" name="main[addressLine2]" id="mainAddressLine2" :class="{ 'has-error' : errors.address_line_2.valid === false}" @blur="validateAddressLine2()" data-cy="main-address-2"/>

                        <span class="help-block form-error" v-if="errors.address_line_2.valid === false" v-html="errors.address_line_2.message" data-cy="main-address-2-error"></span>
                    </div>

                    <label for="mainAddressLine3">Address line 3</label>
                    <input v-model="applicant_address_line_3" type="text" name="main[addressLine3]" id="mainAddressLine3" data-cy="main-address-3"/>

                    <label for="mainAddressLine4">Address line 4</label>
                    <input v-model="applicant_address_line_4" type="text" name="main[addressLine4]" id="mainAddressLine4" data-cy="main-address-4"/>

                    <div class="postcode-wrap">
                        <label for="mainPostcode"><sup>*</sup>Postcode</label>
                        <div class="input-group multiple">
                            <input v-model="applicant_postcode" type="text" name="main[postcode]" id="mainPostcode" :class="{ 'has-error' : errors.postcode.valid === false}" @blur="validatePostcode()" data-cy="main-address-postcode"/>
                            <span class="help-block form-error" v-if="errors.postcode.valid === false && applicant_postcode == ''" v-html="errors.postcode.message" data-cy="main-address-postcode-error"></span>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>

        <fieldset class="additional-details-form">
            <div class="formInner">
                <legend>Additional Information</legend>

                <div class="input-group">
                    <label for="promoCode">Recommended by a friend/colleague? Please enter their policy number here so you both receive vouchers</label>
                    <input v-model="applicant_promo_code" type="text" id="promoCode" name="promoCode" data-cy="main-promo-code"/>
                </div>

                <div class="input-group">
                    <label for="offerCode">Offer code (if you've received an offer code from us, please insert it here)</label>
                    <input v-model="applicant_offer_code" type="text" id="offerCode" name="offerCode" data-cy="main-offer-code"/>
                </div>

                <div class="input-group">
                    <label for="mainReferer"><sup>*</sup>How did you hear about Sovereign Health Care?</label>
                    <div class="select-wrapper">
                        <select v-model="applicant_where_did_you_hear" name="main[referer]" id="mainReferer" :class="{ 'has-error' : errors.where_did_you_hear.valid === false}" @blur="validateWhereDidYouHear()" data-cy="main-referrer">
                            <option disabled value="">Please select</option>
                            <option>Recommended by a colleague</option>
                            <option>Previous customer</option>
                            <option>Picked up a leaflet</option>
                            <option>Site visit from Sovereign</option>
                            <option>Received an email</option>
                            <option>Employer intranet</option>
                            <option>Other</option>
                        </select>
                    </div>
                    <span class="help-block form-error" v-if="errors.where_did_you_hear.valid === false" v-html="errors.where_did_you_hear.message" data-cy="main-referrer-error"></span>
                </div>

            </div>
        </fieldset>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from '../../../../helpers/bus';

    export default {
        props:[],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            applicant_title: {
                get () {
                    return this.application.title;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'title', value.trim() ] );
                }
            },
            applicant_first_name: {
                get () {
                    return this.application.first_name;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'first_name', value.trim() ] );
                }

            },
            applicant_last_name: {
                get () {
                    return this.application.last_name;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'last_name', value.trim() ] );
                }
            },
            applicant_email_address: {
                get () {
                    return this.application.email_address;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'email_address', value.trim() ] );
                }
            },
            applicant_phone_number: {
                get () {
                    return this.application.phone_number;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'phone_number', value.trim() ] );
                }
            },
            applicant_dob_day: {
                get () {
                    return this.application.date_of_birth.day;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'dob_day', value ] );
                }
            },
            applicant_dob_month: {
                get () {
                    return this.application.date_of_birth.month;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'dob_month', value ] );
                }
            },
            applicant_dob_year: {
                get () {
                    return this.application.date_of_birth.year;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'dob_year', value ] );
                }
            },
            applicant_address_line_1: {
                get () {
                    return this.application.address.line_1;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'address_line_1', value.trim() ] );
                }
            },
            applicant_address_line_2: {
                get () {
                    return this.application.address.line_2;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'address_line_2', value.trim() ] );
                }
            },
            applicant_address_line_3: {
                get () {
                    return this.application.address.line_3;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'address_line_3', value.trim() ] );
                }
            },
            applicant_address_line_4: {
                get () {
                    return this.application.address.line_4;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'address_line_4', value.trim() ] );
                }
            },
            applicant_postcode: {
                get () {
                    return this.application.address.postcode;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'postcode', value.trim() ] );
                }
            },
            applicant_promo_code: {
                get () {
                    return this.application.recommended_policy_no;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'recommended_policy_no', value.trim() ] );
                }
            },
            applicant_offer_code: {
                get () {
                    return this.application.offer_code;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'offer_code', value.trim() ] );
                }
            },
            applicant_where_did_you_hear: {
                get () {
                    return this.application.where_did_you_hear;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'where_did_you_hear', value.trim() ] );
                }
            },
            dob_year_range: function() {
                var top_year = moment().subtract(18, 'years').format('Y'),
                    bottom_year = '1916',
                    years = [],
                    year = 0;


                for(var i = top_year; i >= bottom_year; i--){
                    years[year] = i;
                    year++;
                }

                return years;
            },
        },
        created() {
        },
        mounted() {
            let vm = this;
        },
        methods: {
            cleanAddress() {
                let vm = this;
                vm.search_postcode = vm.sanitizeValue(vm.search_postcode);

                vm.validateSearchPostcode();

                jQuery('#mainAddressHide').addClass('hidden');
                jQuery('#mainAddressLines').addClass('hidden');
                jQuery('.reveal-address--main').show();

                vm.$store.dispatch( 'updateApplication', [ 'address_line_1', null ] );
                vm.$store.dispatch( 'updateApplication', [ 'address_line_2', null ] );
                vm.$store.dispatch( 'updateApplication', [ 'address_line_3', null ] );
                vm.$store.dispatch( 'updateApplication', [ 'address_line_4', null ] );
                vm.$store.dispatch( 'updateApplication', [ 'postcode', null ] );
            },
            lookupAddress() {
                var pc = jQuery('#searchPostcode').val();

                var params = {
                    action : 'sov_query_postcode',
                    pc : pc
                };
                jQuery.get('/wp/wp-admin/admin-ajax.php', params, function(response){
                    var json = jQuery.parseJSON(response);
                    if(json.response === true){
                        jQuery('#mainAddressHide').removeClass('hidden');
                        var options = '<option value="">-- Please select your address --</option>';
                        var results = json.results
                        jQuery.each(results, function(i, result){
                            options = options + '<option value="' + result.key + '">' + result.label  + '</option>';
                        });
                        jQuery('#mainAddressSelect').empty().append(options);
                    }
                });
            },
            revealAddress: function() {
                jQuery('#mainAddressLines').removeClass('hidden');
                jQuery('.reveal-address--main').hide();
            },
            updateAddress: function() {
                let vm = this,
                    selected = jQuery('#mainAddressSelect').val();

                var params = {
                    action : 'sov_get_address',
                    key : selected
                }

                jQuery.get('/wp/wp-admin/admin-ajax.php',params,function(response){
                    var json = jQuery.parseJSON(response);

                    if(json.response === true){
                        jQuery('#mainAddressLines').removeClass('hidden');

                        var results = json.results;

                        if(results.property !== undefined){
                            vm.$store.dispatch( 'updateApplication', [ 'address_line_1', results.property ] );
                            vm.$store.dispatch( 'validateApplication', [ 'address_line_1', results.property ] );

                            vm.$store.dispatch( 'updateApplication', [ 'address_line_2', results.street ] );
                            vm.$store.dispatch( 'validateApplication', [ 'address_line_2', results.street ] );

                            if( results.locality ) {
                                vm.$store.dispatch( 'updateApplication', [ 'address_line_3', results.locality + ', ' + results.town ] );
                            } else {
                                vm.$store.dispatch( 'updateApplication', [ 'address_line_3', results.town ] );
                            }

                            vm.$store.dispatch( 'updateApplication', [ 'address_line_4', results.county ] );

                            vm.$store.dispatch( 'updateApplication', [ 'postcode', results.postcode ] );
                            vm.$store.dispatch( 'validateApplicationPostcode', [ 'postcode', results.postcode ] );
                        } else {
                            vm.$store.dispatch( 'updateApplication', [ 'address_line_1', results.street ] );
                            vm.$store.dispatch( 'validateApplication', [ 'address_line_1', results.street ] );

                            if( results.locality ) {
                                vm.$store.dispatch( 'updateApplication', [ 'address_line_2', results.locality ] );
                                vm.$store.dispatch( 'validateApplication', [ 'address_line_2', results.locality ] );

                                vm.$store.dispatch( 'updateApplication', [ 'address_line_3', results.town ] );
                                vm.$store.dispatch( 'updateApplication', [ 'address_line_4', results.county ] );
                            } else {
                                vm.$store.dispatch( 'updateApplication', [ 'address_line_2', results.town ] );
                                vm.$store.dispatch( 'validateApplication', [ 'address_line_2', results.town ] );

                                vm.$store.dispatch( 'updateApplication', [ 'address_line_3', results.county ] );
                            }

                            vm.$store.dispatch( 'updateApplication', [ 'postcode', results.postcode ] );
                            vm.$store.dispatch( 'validateApplicationPostcode', [ 'postcode', results.postcode ] );
                        }
                    }
                });
            },
            updateCoverLevel( cover_level ) {
                let vm = this;
                vm.$store.dispatch( 'updateCoverLevel', cover_level );
                vm.$store.dispatch( 'validateApplication', [ 'cover_level', cover_level ] );
            },
            updateDOB( type ) {
                let vm = this;

                vm.editing_dob[type] = true;

                if( vm.editing_dob.day && vm.editing_dob.month && vm.editing_dob.year ) {
                    vm.$store.dispatch( 'validateApplicationDOB', [ 'dob_day', vm.application.date_of_birth.day ] );
                    vm.$store.dispatch( 'validateApplicationDOB', [ 'dob_month', vm.application.date_of_birth.month ] );
                    vm.$store.dispatch( 'validateApplicationDOB', [ 'dob_year', vm.application.date_of_birth.year ] );
                }
            },
            validateTitle() {
                let vm = this;
                vm.$store.dispatch( 'validateApplication', [ 'title', vm.application.title ] );
            },
            validateFirstName() {
                let vm = this;
                vm.$store.dispatch( 'validateApplication', [ 'first_name', vm.application.first_name ] );
            },
            validateLastName() {
                let vm = this;
                vm.$store.dispatch( 'validateApplication', [ 'last_name', vm.application.last_name ] );
            },
            validateEmailAddress() {
                let vm = this;
                vm.$store.dispatch( 'validateApplicationEmailAddress', [ 'email_address', vm.application.email_address ] );
                EventBus.$emit('updatePrimaryEmailAddress');
            },
            validatePhoneNumber() {
                let vm = this;
                vm.$store.dispatch( 'validateApplicationPhoneNumber', [ 'phone_number', vm.application.phone_number ] );
            },
            validateAddressLine1() {
                let vm = this;
                vm.$store.dispatch( 'validateApplication', [ 'address_line_1', vm.application.address.line_1 ] );
                EventBus.$emit('updatePrimaryAddress');
            },
            validateAddressLine2() {
                let vm = this;
                vm.$store.dispatch( 'validateApplication', [ 'address_line_2', vm.application.address.line_2 ] );
            },
            validateSearchPostcode() {
                let vm = this;
                vm.valid_search_postcode = vm.checkValidPostcode( vm.search_postcode, false, 'main' );
            },
            validatePostcode() {
                let vm = this;
                vm.$store.dispatch( 'validateApplicationPostcode', [ 'postcode', vm.application.address.postcode ] );
                EventBus.$emit('updatePrimaryAddress');
            },
            validateWhereDidYouHear() {
                let vm = this;
                vm.$store.dispatch( 'validateApplication', [ 'where_did_you_hear', vm.application.where_did_you_hear ] );
            }
        },
        data () {
            return {
                editing_dob: {
                    day: false,
                    month: false,
                    year: false
                },
                search_postcode: '',
                valid_search_postcode: ''
            }
        }
    }
</script>
