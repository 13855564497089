<template>
    <div class="dashboard" :class="{ 'loading' : loading }">
        <span class="loading-spinner" v-if="loading"></span>
        <div class="application-stage application-start">
            <div class="expander-box">
                <h2>Before you start, please note the following:</h2>
                <div class="content active">
                    <ul class="flex">
                        <li><img :src="this.images_path + '/icon-18.png'" /> You must be age 18 or over</li>
                        <li><img :src="this.images_path + '/icon-uk-resident.png'" /> You must be a UK resident</li>
                        <li><img :src="this.images_path + '/icon-payroll.png'" /> You have your payroll/employee reference number to hand</li>
                    </ul>
                </div>
            </div>
            <div class="expander-box">
                <h2>Do you want to cover someone else and pay for their policy?</h2>
                <div class="content active">
                    <p>If you are NOT a Sovereign Health Care customer yourself but you want to apply on behalf of someone else and pay for their cover, please call us on <strong>0800 678 5605</strong> to do this. Lines are open Monday to Thursday from 9am to 5pm, and Fridays from 9am to 4pm.</p>
                </div>
            </div>
            <div class="expander-box payment-type">
                <h2>Please tell us how frequently you are paid</h2>
                <div class="content active">
                    <p>To start your application and pay your premiums by payroll deduction, please tell us how frequently you are paid by your employer. If you apply and a payroll deduction isn't in place with your employer, we will write to you and you can choose to pay by Direct Debit instead.</p>
                    <div class="buttons">
                        <p>Please tell us how frequently you are paid:</p>
                        <a class="btn btn--primary" @click="updatePaymentType('weekly')" data-cy="paid-weekly-button">Weekly</a>
                        <a class="btn btn--primary" @click="updatePaymentType('four-weekly')" data-cy="paid-4-weekly-button">Every 4 Weeks</a>
                        <a class="btn btn--primary" @click="updatePaymentType('monthly')" data-cy="paid-monthly-button">Monthly</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from './../../../helpers/bus';

    export default {
        props:[],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            )
        },
        created() {
        },
        mounted() {
            let vm = this;
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);

            setTimeout( function(){
                window.scrollTo(0,0);
                vm.loading = false;
            }, 500);

            vm.$ga.page({
                page: '/',
                title: 'Apply Now',
                location: window.location.origin + '/'
            });

            if( params.get("stage") ) {
                history.pushState(null, 'Apply Now', '/');
            }
        },
        methods: {
            updatePaymentType( payment_type ) {
                let vm = this;

                vm.$store.dispatch( 'updatePaymentType', payment_type );
                vm.$store.dispatch( 'updateStage', [ 'select-cover', true ] );

                history.pushState(null, 'Select Cover', '/?stage=select-cover');
                EventBus.$emit('updateStageURL');
            }
        },
        data () {
            return {
                loading: true,
            }
        }
    }
</script>
