<template>
    <div class="application-form-wrap" :class="{ 'loading' : loading }">
        <span class="loading-spinner" v-if="loading"></span>
        <div class="application-stage">
            <application-step-indicator></application-step-indicator>
            <form id="appFormTwo" method="post">

                <div class="main-col validation-error-col" v-if="show_errors && is_stage_two_valid === false">
                    <div class="main-error">
                        <p>Please ensure all required fields are completed</p>
                    </div>
                </div>

                <fieldset class="main-col">
                    <div class="formInner">
                        <legend>Payroll Details</legend>
                        <div class="intro-text" v-html="payroll_details"></div>

                        <div class="input-group input-payroll">
                            <label for="payrollNumber">
                                <sup>*</sup>Payroll/Employee reference number
                                <div class="pop-out-wrap">
                                    <span class="pop-out-link"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                                    <div class="pop-out">This can usually be found on your payslip.</div>
                                </div>
                            </label>
                            <input v-model="applicant_payroll_number" type="text" name="main[payroll_number]" id="mainPayrollNumber" :class="{ 'has-error' : errors.payroll_number.valid === false}" @blur="validatePayRoll()" data-cy="payroll-number"/>
                            
                            <span class="help-block form-error" v-if="errors.payroll_number.valid === false" v-html="errors.payroll_number.message" data-cy="payroll-number-error"></span>
                        </div>

                        <div class="input-group input-employer">
                            <label for="employerName">
                                <sup>*</sup>Employer name
                                <div class="pop-out-wrap">
                                    <span class="pop-out-link"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                                    <div class="pop-out">The company which pays your wages.</div>
                                </div>
                            </label>
                            <input v-model="applicant_employer_name" type="text"  name="main[employer_name]" id="mainEmployerName" :class="{ 'has-error' : errors.employer_name.valid === false}"  @blur="validateEmployer()" data-cy="employer-name"/>
                            
                            <span class="help-block form-error" v-if="errors.employer_name.valid === false" v-html="errors.employer_name.message" data-cy="employer-name-error"></span>
                        </div>

                        <div class="input-group input-location">
                            <label for="location">
                                Location (where you are based)
                                <div class="pop-out-wrap">
                                    <span class="pop-out-link"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                                    <div class="pop-out">If you work for a company with multiple sites, it would be helpful if you could tell us which location you are based at.</div>
                                </div>
                            </label>
                            <input v-model="applicant_location" type="text" name="main[location]" id="mainLocation" data-cy="employer-location"/>
                        </div>

                        <div class="input-group input-payment-type">
                            <label for="location"><sup>*</sup>Payment frequency</label>
                            
                            <div class="buttons">
                                <a class="btn btn--primary" :class="{ 'active' : application.payment_type == 'weekly' }" @click="updatePaymentType('weekly')">Weekly</a>
                                <a class="btn btn--primary" :class="{ 'active' : application.payment_type == 'four-weekly' }" @click="updatePaymentType('four-weekly')">Every 4 Weeks</a>
                                <a class="btn btn--primary" :class="{ 'active' : application.payment_type == 'monthly' }" @click="updatePaymentType('monthly')">Monthly</a>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <application-important-notes></application-important-notes>
                <application-summary-box></application-summary-box>

            </form>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from '../../../helpers/bus';

    import ApplicationStepIndicator from '../../blocks/application-step-indicator';

    import ApplicationImportantNotes from '../../blocks/application-important-notes';
    import ApplicationSummaryBox from '../../blocks/application-summary-box';

    export default {
        props:[],
        components:{
            ApplicationStepIndicator,
            ApplicationImportantNotes,
            ApplicationSummaryBox
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            applicant_payroll_number: {
                get () {
                    return this.application.payroll_number;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'payroll_number', value.trim() ] );
                }
            },
            applicant_employer_name: {
                get () {
                    return this.application.employer_name;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'employer_name', value.trim() ] );
                }
            },
            applicant_location: {
                get () {
                    return this.application.location;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'location', value.trim() ] );
                }
            },
        },
        created() {
            let vm = this,
                api_url = '/wp-json/acf/v3/options/option';

            axios.get(api_url)
                .then(response => { 
                    if( response.status == '200' ) {
                        vm.payroll_details = response.data.acf.app_step_two_payroll_details;

                        setTimeout( function(){
                            window.scrollTo(0,0);
                            vm.loading = false;
                        }, 500);
                    }
                })
                .catch( error => { });
        },
        mounted() {
            var vm = this;
            vm.$ga.page({
                page: '/step-2/',
                title: 'Step 2',
                location: window.location.origin + '/?stage=step-2'
            });

            EventBus.$on('validateAllStep2', function(data) {
                vm.validateStep2();
                vm.show_errors = true;
            });
        },
        methods: {
            updatePaymentType( payment_type ) {
                let vm = this;
                vm.$store.dispatch( 'updatePaymentType', payment_type );
            },
            validatePayRoll() {
                let vm = this;
                vm.$store.dispatch( 'validateApplication', [ 'payroll_number', vm.application.payroll_number ] );
            },
            validateEmployer() {
                let vm = this;
                vm.$store.dispatch( 'validateApplication', [ 'employer_name', vm.application.employer_name ] );
            },
            validateStep2() {
                let vm = this;

                vm.$store.dispatch( 'validateApplication', [ 'payroll_number', vm.application.payroll_number ] );
                vm.$store.dispatch( 'validateApplication', [ 'employer_name', vm.application.employer_name ] );
            },
        },
        data () {
            return {
                loading: true,
                payroll_details: "",
                show_errors: false
            }
        }
    }
</script>