<template>
    <table class="table-application">
        <payroll-table-header :benefits="benefits"></payroll-table-header>
        <template v-for="(benefit, benefit_key) in benefits">
            <tr class="header" :key="'benefit-header_'+benefit_key">
                <td class="al" v-html="benefit.section_title"></td>
                <td>
                    Payback
                </td>
                <td>
                    <div class="pop-out-wrap">
                        <a class="pop-out-link">
                            <img :src="images_path + '/icon-people.png'" />
                        </a>
                        <div class="pop-out">Dependent children up to the age of 18 covered for free</div>
                    </div>
                </td>
                <td colspan="5"></td>
            </tr>
            <template v-for="(single_benefit, single_benefit_key) in benefit.benefit">
                <tr :key="'benefit-details_'+benefit_key+'-'+single_benefit_key">
                    <td class="al" :colspan="single_benefit.no_levels ? '8' : '1'">
                        <div class="cell-content">
                            <span><strong v-html="single_benefit.title"></strong> <span v-html="single_benefit.text"></span></span>
                            <img class="coverExpand" :src="images_path + '/white-arrow-up.svg'" /> 
                        </div>
                    </td>
                    <template v-if="single_benefit.no_levels === false">
                        <td v-html="paybackLabel(single_benefit.payback)"></td>
                        <td>
                            <span :class="(single_benefit.dependents == 'yes') ? 'true' : 'false'" v-html="single_benefit.dependents"></span>
                        </td>
                        <td class="tab-level-info tab-level-1 active" data-level="1">
                            <span class="lg" v-if="single_benefit.wording == 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                            <span class="money" v-html="single_benefit.level_1"></span>
                            <span class="lg" v-if="single_benefit.wording != 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                        </td>
                        <td class="tab-level-info tab-level-2 alt" data-level="2">
                            <span class="lg" v-if="single_benefit.wording == 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                            <span class="money" v-html="single_benefit.level_2"></span>
                            <span class="lg" v-if="single_benefit.wording != 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                        </td>
                        <td class="tab-level-info tab-level-3" data-level="3">
                            <span class="lg" v-if="single_benefit.wording == 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                            <span class="money" v-html="single_benefit.level_3"></span>
                            <span class="lg" v-if="single_benefit.wording != 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                        </td>
                        <td class="tab-level-info tab-level-4 alt" data-level="4">
                            <span class="lg" v-if="single_benefit.wording == 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                            <span class="money" v-html="single_benefit.level_4"></span>
                            <span class="lg" v-if="single_benefit.wording != 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                        </td>
                        <td class="tab-level-info tab-level-5" data-level="5">
                            <span class="lg" v-if="single_benefit.wording == 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                            <span class="money" v-html="single_benefit.level_5"></span>
                            <span class="lg" v-if="single_benefit.wording != 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                        </td>
                    </template>
                </tr>
                <tr v-if="single_benefit.summary_text" :key="'cover-info_'+benefit_key+'-'+single_benefit_key" class="coverInfo" >
                    <td colspan="8">
                        <div class="colHead" v-html="single_benefit.summary_text"></div>
                        <div class="col half" v-if="single_benefit.we_will_pay" v-html="single_benefit.we_will_pay"></div>
                        <div class="col half" v-if="single_benefit.we_will_not_pay" v-html="single_benefit.we_will_not_pay"></div>
                    </td>
                </tr>
            </template>
            <tr v-if="benefit.section_summary_text" :key="'benefit-summary_'+benefit_key">
                <td class="al" colspan="8"><strong>Telephone helpline</strong> Available 24 hours a day, 365 days a year. Provided by Optum. Includes a medical helpline, telephone counselling, specialist legal, financial and debt information as well as online support on a wide range of life issues</td>
            </tr>
        </template>
        <payroll-table-header :benefits="benefits"></payroll-table-header>
    </table>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    import PayrollTableHeader from './table-header';

    export default {
        props:[ 'benefits' ],
        components:{
            PayrollTableHeader
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            )
        },
        created() {
        },
        mounted() {
        },
        methods: {
            paybackLabel( payback ) {
                var label = '';
                switch( payback ){
                    case 'fifty':
                        label = '<span class="bt num">50%</span>';
                        break;
                    case 'hundred':
                        label = '<span class="bt num">100%</span>';
                        break;
                    case 'max-7-nights':
                        label = '<span class="bt">Max <em>7</em> days/nights<span>';
                        break;
                    case 'fixed-amount':
                        label = '<span class="bt">Fixed<br /> amount</span>';
                        break;
                    default: 
                        label = '';
                        break;
                } 
                return label;
            },
            wordingLabel( wording ) {
                var label = '';

                switch( wording ){
                    case 'per-child':
                        label = 'per child';
                        break;
                    case 'per-day':
                        label = 'per day/night';
                        break;
                    case 'upto':
                        label = 'up to';
                        break;
                    default: 
                        label = '';
                        break;
                } 
                return label;
            }
        },
        data () {
            return {
            }
        }
    }
</script>