<template>
    <div class="application-form-wrap" :class="{ 'loading' : loading }">
        <span class="loading-spinner" v-if="loading"></span>
        <div class="application-stage stage-three">
            <application-step-indicator></application-step-indicator>
            <form id="appFormThree" method="post">

                <div class="main-col summary-large-text">
                    <div class="formInner" v-html="check_details"></div>
                </div>

                <application-summary-primary></application-summary-primary>
                <application-summary-adults></application-summary-adults>
                <application-summary-children></application-summary-children>
                <application-summary-payroll></application-summary-payroll>

                <application-declaration></application-declaration>

                <application-summary-final></application-summary-final>

            </form>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from '../../../helpers/bus';

    import ApplicationStepIndicator from '../../blocks/application-step-indicator';

    import ApplicationSummaryPrimary from './blocks/application-summary-primary';
    import ApplicationSummaryAdults from './blocks/application-summary-adults';
    import ApplicationSummaryChildren from './blocks/application-summary-children';
    import ApplicationSummaryPayroll from './blocks/application-summary-payroll';

    import ApplicationDeclaration from './blocks/application-declaration';

    import ApplicationSummaryFinal from './blocks/application-summary-final';

    export default {
        props:[],
        components:{
            ApplicationStepIndicator,
            ApplicationSummaryPrimary,
            ApplicationSummaryAdults,
            ApplicationSummaryChildren,
            ApplicationSummaryPayroll,
            ApplicationDeclaration,
            ApplicationSummaryFinal
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
        },
        created() {
            let vm = this,
                api_url = '/wp-json/acf/v3/options/option';

            axios.get(api_url)
                .then(response => { 
                    if( response.status == '200' ) {
                        vm.check_details = response.data.acf.app_step_three_check_details;

                        setTimeout( function(){
                            window.scrollTo(0,0);
                            vm.loading = false;
                        }, 500);
                    }
                })
                .catch( error => { });
        },
        mounted() {
            var vm = this;

            vm.$ga.page({
                page: '/step-3/',
                title: 'Step 3',
                location: window.location.origin + '/?stage=step-3'
            });

            EventBus.$on('validateAllStep3', function(data) {
                vm.validateStep3();
                window.scrollTo( 0, jQuery('.confirmation-summary').offset().top - 100 );
            });
        },
        methods: {
            validateStep3() {
                let vm = this;
                vm.$store.dispatch( 'validateApplicationConfirmation', vm.application.confirmation );
            }
        },
        data () {
            return {
                check_details: '',
                loading: true
            }
        }
    }
</script>