<template>
    <div class="dashboard" :class="{ 'loading' : loading }">
        <span class="loading-spinner" v-if="loading"></span>
        <div class="application-stage application-start" v-if="benefits !== null">

            <div class="expander-box">
                <h2>Choose your level of cover from the table below.</h2>
                <div class="content active" v-html="select_cover_details"></div>
            </div>

            <div class="mobile-tabs">
                <p>Choose a level of cover to view more details</p>
                <ul>
                    <li><a class="tab-level-link active" data-level="1">1</a></li>
                    <li><a class="tab-level-link" data-level="2">2</a></li>
                    <li><a class="tab-level-link" data-level="3">3</a></li>
                    <li><a class="tab-level-link" data-level="4">4</a></li>
                    <li><a class="tab-level-link" data-level="5">5</a></li>
                </ul>
            </div>
            <payroll-table :benefits="benefits"></payroll-table>

            <p class="premium-tax-note"><small>Premiums include insurance premium tax (IPT).</small></p>

            <div class="mobile-tabs">
                <p>Choose a level of cover to view more details</p>
                <ul>
                    <li><a  class="tab-level-link active" data-level="1">1</a></li>
                    <li><a  class="tab-level-link" data-level="2">2</a></li>
                    <li><a  class="tab-level-link" data-level="3">3</a></li>
                    <li><a  class="tab-level-link" data-level="4">4</a></li>
                    <li><a  class="tab-level-link" data-level="5">5</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    import PayrollTableHeader from './table/table-header';
    import PayrollTable from './table/table';

    export default {
        props:[],
        components:{
            PayrollTableHeader,
            PayrollTable
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            )
        },
        created() {
            let vm = this,
                benefits_api_url = '/wp-json/sovereign/v1/get-benefits',
                options_api_url = '/wp-json/acf/v3/options/option';

            axios.get(benefits_api_url)
                .then(response => {
                    if( response.status == '200' ) {
                        vm.benefits = response.data;
                    }
                })
                .catch( error => { });

            axios.get(options_api_url)
                .then(response => {
                    if( response.status == '200' ) {
                        vm.select_cover_details = response.data.acf.app_select_cover_details;

                        setTimeout( function(){
                            window.scrollTo(0,0);
                            vm.loading = false;
                        }, 500);
                    }
                })
                .catch( error => { });
        },
        mounted() {
            var vm = this;
            vm.$ga.page({
                page: '/select-cover/',
                title: 'Select Cover',
                location: window.location.origin + '/?stage=select-cover'
            });
        },
        methods: {
        },
        data () {
            return {
                benefits: false,
                loading: true,
                select_cover_details: ''
            }
        }
    }
</script>
