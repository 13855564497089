import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist';
import Crypto from 'crypto-js';
import Cookie from 'js-cookie';
import { v4 as uuid }  from 'uuid';

const cookieName = 'payrollApplicationKey';
const storageKey = 'payrollApplicationKey';

// Get the encryption token from cookie or generate a new one.
const encryptionToken = Cookie.get(cookieName) || uuid();

// Store the encryption token in a secure cookie.
Cookie.set(cookieName, encryptionToken, { secure: true });

const vuexSessionStorage = new VuexPersistence({
    key: 'vuex',
    storage: {
        getItem: () => {
            const store = window.sessionStorage.getItem(storageKey);
            if (store) {
                try {
                    const bytes = Crypto.AES.decrypt(store, encryptionToken);
                    return JSON.parse(bytes.toString(Crypto.enc.Utf8));
                } catch (e) {
                    window.sessionStorage.removeItem(storageKey);
                }
            }
            return null;
        },
        setItem: (key, value) => {
            const store = Crypto.AES.encrypt(value, encryptionToken).toString();
            return window.sessionStorage.setItem(storageKey, store);
        },
        removeItem: () => window.sessionStorage.removeItem(storageKey),
    }, 
    reducer: state => ({
        stages: state.stages,
        application: state.application,
        prices: state.prices,
        errors: state.errors,
        jump_to: state.jump_to
    })
});

const store = new Vuex.Store({
    state: {
        stages: {
            'get-started': true,
            'select-cover': false,
            'step-1': false,
            'step-2': false,
            'step-3': false,
            'confirmation': false
        },
        prices: null,
        application: {
            application_id: null,
            payment_type: null,
            cover_level: null,
            title: '',
            first_name: null,
            last_name: null,
            date_of_birth: {
                day: '',
                month: '',
                year: ''
            },
            email_address: null,
            phone_number: null,
            address: {
                line_1: null,
                line_2: null,
                line_3: null,
                line_4: null,
                postcode: null
            },
            recommended_policy_no: null,
            offer_code: null,
            where_did_you_hear: '',
            additional_policies: {
                adults: [],
                children: []
            },
            payroll_number: null,
            employer_name: null,
            location: null,
            marketing: {
                post: false,
                phone: false,
                email: false,
                text: false
            },
            confirmation: null
        },
        errors: {
            cover_level: {
                valid: null,
                required: true,
                message: '',
                messages: {
                    empty: 'Please select a level of cover',
                }
            },
            title: {
                valid: null,
                required: true,
                message: '',
                messages: {
                    empty: 'Please provide your title',
                    empty_adult: 'Please provide your partner\'s/family member\'s title',
                    empty_child: 'Please provide your child\'s title',
                }
            },
            first_name: {
                valid: null,
                required: true,
                message: '',
                messages: {
                    empty: 'Please provide your first name',
                    empty_adult: 'Please provide your partner\'s/family member\'s first name',
                    empty_child: 'Please provide your child\'s first name',
                }
            },
            last_name: {
                valid: null,
                required: true,
                message: '',
                messages: {
                    empty: 'Please provide your surname',
                    empty_adult: 'Please provide your partner\'s/family member\'s surname',
                    empty_child: 'Please provide your child\'s surname',
                }
            },
            date_of_birth: {
                valid: null,
                required: true,
                message: '',
                messages: {
                    future: 'Date of birth cannot be in the future',
                    empty: 'Please provide your date of birth',
                    empty_adult: 'Please provide your partner\'s/family member\'s date of birth',
                    empty_child: 'Please provide your child\'s date of birth',
                    not_date: 'Please provide a valid date of birth',
                    too_young_main: 'You must be age 18 or over to join',
                    too_old_main: 'You must be age 105 or under to join',
                    too_young_adult: 'Your partner/family member must be age 18 or over to join',
                    too_old_adult: 'Your partner/family member must be age 105 or under to join',
                    too_old: 'Your child must be under the age of 18'
                }
            },
            email_address: {
                valid: null,
                required: true,
                message: '',
                messages: {
                    empty: 'Please provide your email address',
                    invalid: 'Please provide a valid email address',
                    invalid_adult: 'Please provide a valid email address for your partner/family member.',
                    invalid_adult_duplicate: 'Please provide a valid email address for your partner/family member. It must be different from your email address',
                }
            },
            phone_number: {
                valid: null,
                required: true,
                message: '',
                messages: {
                    empty: 'Please provide your contact telephone number',
                    invalid: 'Please provide a valid contact telephone number'
                }
            },
            address_line_1: {
                valid: null,
                required: true,
                message: '',
                messages: {
                    empty: 'Please provide the first line of your address',
                    empty_adult: 'Please provide the first line of your partner\'s/family member\'s address'
                }
            },
            address_line_2: {
                valid: null,
                required: true,
                message: '',
                messages: {
                    empty: 'Please provide the second line of your address',
                    empty_adult: 'Please provide the second line of your partner\'s/family member\'s address'
                }
            },
            postcode: {
                valid: null,
                required: true,
                message: '',
                messages: {
                    empty: 'Please provide your postcode',
                    invalid: 'Please provide a valid postcode',
                    invalid_adult: 'Please provide a valid postcode for your partner/family member',
                    invalid_adult_duplicate: 'If provided, your partner\'s/family member\'s address must be different from yours',
                }
            },
            where_did_you_hear: {
                valid: null,
                required: true,
                message: '',
                messages: {
                    empty: 'Please select where you heard about Sovereign Health Care'
                }
            },
            payroll_number: {
                valid: null,
                required: true,
                message: '',
                messages: {
                    empty: 'Please provide your Payroll/Employee reference number'
                }
            },
            employer_name: {
                valid: null,
                required: true,
                message: '',
                messages: {
                    empty: 'Please provide your employer\'s name'
                }
            },
            confirmation: {
                valid: null,
                required: true,
                message: '',
                messages: {
                    empty: 'Please tick this box to say you have read and agree to the policy terms and conditions'
                }
            }
        }
    },
    mutations: {
        UPDATE_APPLICATION(state, [key, value]) {

            if ( typeof value === 'string' ) {
                var value = value.replace(/[^A-Za-z 0-9_'-/\\&/\\@]/gi, '');
            }

            if (key == 'dob_day') {
                state.application['date_of_birth']['day'] = value;
            } else if (key == 'dob_month') {
                state.application['date_of_birth']['month'] = value;
            } else if (key == 'dob_year') {
                state.application['date_of_birth']['year'] = value;
            } else if (key == 'address_line_1') {
                state.application['address']['line_1'] = value;
            } else if (key == 'address_line_2') {
                state.application['address']['line_2'] = value;
            } else if (key == 'address_line_3') {
                state.application['address']['line_3'] = value;
            } else if (key == 'address_line_4') {
                state.application['address']['line_4'] = value;
            } else if (key == 'postcode') {
                if (value !== null) {
                    state.application['address']['postcode'] = value.toUpperCase();
                } else {
                    state.application['address']['postcode'] = null;
                }
            }
            else {
                state.application[key] = value;
            }
        },
        UPDATE_COVER_LEVEL(state, cover_level) {
            state.application.cover_level = cover_level;
        },
        UPDATE_PAYMENT_TYPE(state, payment_type) {
            state.application.payment_type = payment_type;
        },
        UPDATE_PRICES(state, prices) {
            state.prices = prices;
        },
        UPDATE_STAGE(state, [stage, can_view]) {
            state.stages[stage] = can_view;
        },
        ADD_ADULT_APPLICANT(state, adult) {
            if (adult.postcode !== null) adult.postcode = adult.postcode.toUpperCase();
            state.application.additional_policies.adults.push(adult);
        },
        UPDATE_ADULT_APPLICANT(state, [adult_id, adult]) {
            if (adult.postcode !== null) adult.postcode = adult.postcode.toUpperCase();
            state.application.additional_policies.adults[adult_id] = adult;
        },
        REMOVE_ADULT_APPLICANT(state, adult_id) {
            state.application.additional_policies.adults.splice(adult_id, 1);
            state.application.additional_policies.adults = state.application.additional_policies.adults.filter(val => val);
        },
        ADD_CHILD_APPLICANT(state, child) {
            state.application.additional_policies.children.push(child);
        },
        UPDATE_CHILD_APPLICANT(state, [child_id, child]) {
            state.application.additional_policies.children[child_id] = child;
        },
        REMOVE_CHILD_APPLICANT(state, child_id) {
            state.application.additional_policies.children.splice(child_id, 1);
            state.application.additional_policies.children = state.application.additional_policies.children.filter(val => val);
        },
        RESTART_APPLICATION(state) {

            state.stages = {
                'get-started': true,
                'select-cover': false,
                'step-1': false,
                'step-2': false,
                'step-3': false,
                'confirmation': false
            };

            state.application = {
                payment_type: null,
                cover_level: null,
                title: '',
                first_name: null,
                last_name: null,
                date_of_birth: {
                    day: '',
                    month: '',
                    year: '',
                },
                email_address: null,
                phone_number: null,
                address: {
                    line_1: null,
                    line_2: null,
                    line_3: null,
                    line_4: null,
                    postcode: null
                },
                recommended_policy_no: null,
                offer_code: null,
                where_did_you_hear: '',
                additional_policies: {
                    adults: [],
                    children: []
                },
                payroll_number: null,
                employer_name: null,
                location: null,
                marketing: {
                    post: false,
                    phone: false,
                    email: false,
                    text: false
                }
            };

            state.errors = {
                cover_level: {
                    valid: null,
                    required: true,
                    message: '',
                    messages: {
                        empty: 'Please select a level of cover',
                    }
                },
                title: {
                    valid: null,
                    required: true,
                    message: '',
                    messages: {
                        empty: 'Please provide your title',
                        empty_adult: 'Please provide your partner\'s/family member\'s title',
                        empty_child: 'Please provide your child\'s title',
                    }
                },
                first_name: {
                    valid: null,
                    required: true,
                    message: '',
                    messages: {
                        empty: 'Please provide your first name',
                        empty_adult: 'Please provide your partner\'s/family member\'s first name',
                        empty_child: 'Please provide your child\'s first name',
                    }
                },
                last_name: {
                    valid: null,
                    required: true,
                    message: '',
                    messages: {
                        empty: 'Please provide your surname',
                        empty_adult: 'Please provide your partner\'s/family member\'s surname',
                        empty_child: 'Please provide your child\'s surname',
                    }
                },
                date_of_birth: {
                    valid: null,
                    required: true,
                    message: '',
                    messages: {
                        future: 'Date of birth cannot be in the future',
                        empty: 'Please provide your date of birth',
                        empty_adult: 'Please provide your partner\'s/family member\'s date of birth',
                        empty_child: 'Please provide your child\'s date of birth',
                        not_date: 'Please provide a valid date of birth',
                        too_young_main: 'You must be age 18 or over to join',
                        too_old_main: 'You must be age 105 or under to join',
                        too_young_adult: 'Your partner/family member must be age 18 or over to join',
                        too_old_adult: 'Your partner/family member must be age 105 or under to join',
                        too_old: 'Your child must be under the age of 18'
                    }
                },
                email_address: {
                    valid: null,
                    required: true,
                    message: '',
                    messages: {
                        empty: 'Please provide your email address',
                        invalid: 'Please provide a valid email address',
                        invalid_adult: 'Please provide a valid email address for your partner/family member.',
                        invalid_adult_duplicate: 'Please provide a valid email address for your partner/family member. It must be different from your email address',
                    }
                },
                phone_number: {
                    valid: null,
                    required: true,
                    message: '',
                    messages: {
                        empty: 'Please provide your contact telephone number',
                        invalid: 'Please provide a valid contact telephone number'
                    }
                },
                address_line_1: {
                    valid: null,
                    required: true,
                    message: '',
                    messages: {
                        empty: 'Please provide the first line of your address',
                        empty_adult: 'Please provide the first line of your partner\'s/family member\'s address'
                    }
                },
                address_line_2: {
                    valid: null,
                    required: true,
                    message: '',
                    messages: {
                        empty: 'Please provide the second line of your address',
                        empty_adult: 'Please provide the second line of your partner\'s/family member\'s address'
                    }
                },
                postcode: {
                    valid: null,
                    required: true,
                    message: '',
                    messages: {
                        empty: 'Please provide your postcode',
                        invalid: 'Please provide a valid postcode',
                        invalid_adult: 'Please provide a valid postcode for your partner/family member',
                        invalid_adult_duplicate: 'If provided, your partner\'s/family member\'s address must be different from yours',
                    }
                },
                where_did_you_hear: {
                    valid: null,
                    required: true,
                    message: '',
                    messages: {
                        empty: 'Please select where you heard about Sovereign Health Care'
                    }
                },
                payroll_number: {
                    valid: null,
                    required: true,
                    message: '',
                    messages: {
                        empty: 'Please provide your Payroll/Employee reference number'
                    }
                },
                employer_name: {
                    valid: null,
                    required: true,
                    message: '',
                    messages: {
                        empty: 'Please provide your employer\'s name'
                    }
                },
                confirmation: {
                    valid: null,
                    required: true,
                    message: '',
                    messages: {
                        empty: 'Please tick this box to say you have read and agree to the policy terms and conditions'
                    }
                }
            }
        },
        VALIDATE_APPLICATION( state, [ key, value ] ) {
            if( state.errors[key].required && ( value == '' || value == null ) ){
                state.errors[key].valid = false;
                state.errors[key].message = state.errors[key].messages.empty;
            } else {
                state.errors[key].valid = true;
                state.errors[key].message = '';
            }
        },
        VALIDATE_APPLICATION_CONFIRMATION(state, value) {
            if (state.application.confirmation == null) {
                state.errors['confirmation'].valid = false;
            } else {
                state.errors['confirmation'].valid = state.application.confirmation;
            }
        },
        VALIDATE_APPLICATION_DOB( state, [ key, value ] ) {

            var days_in_month = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

            // Adjust for leap years
            if( state.application['date_of_birth']['year'] % 400 == 0 || ( state.application['date_of_birth']['year'] % 100 != 0 && state.application['date_of_birth']['year'] % 4 == 0 ) ) {
                days_in_month[1] = 29;
            }

             if( state.errors['date_of_birth'].required && ( value == '' || value == null ) ){
                state.errors['date_of_birth'].valid = false;
                state.errors['date_of_birth'].message = state.errors['date_of_birth'].messages.empty;
            } else {

                 if( state.application['date_of_birth']['day'] !== ''
                    && state.application['date_of_birth']['month'] !== ''
                    && state.application['date_of_birth']['year'] !== '' ) {
                        var current_date = moment();
                        var applicant_dob = moment(state.application['date_of_birth']['day'] + '-' + state.application['date_of_birth']['month'] + '-' + state.application['date_of_birth']['year'], 'DD-MM-YYYY');
                        var age = current_date.diff(applicant_dob, 'years');

                        if( state.application['date_of_birth']['day'] > days_in_month[ state.application['date_of_birth']['month'] - 1 ] ) {
                            state.errors['date_of_birth'].valid = false;
                            state.errors['date_of_birth'].message = state.errors['date_of_birth'].messages.not_date;
                        } else if( age > 17 && age < 105 ) {
                            state.errors['date_of_birth'].valid = true;
                        } else if( age < 18 ) {
                            state.errors['date_of_birth'].valid = false;
                            state.errors['date_of_birth'].message = state.errors['date_of_birth'].messages.too_young_main;
                        } else {
                            state.errors['date_of_birth'].valid = false;
                            state.errors['date_of_birth'].message = state.errors['date_of_birth'].messages.too_old_main;
                        }
                }
            }
        },
        VALIDATE_APPLICATION_EMAIL_ADDRESS(state, [key, value]) {
            var email_address = value,
                regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;

            if (state.errors['email_address'].required && (email_address === '' || email_address === null)) {
                state.errors['email_address'].valid = false;
                state.errors['email_address'].message = state.errors['email_address'].messages.empty;
            } else {
                if (regex.test(email_address)) {
                    state.errors['email_address'].valid = true;
                } else if (email_address !== null) {
                    state.errors['email_address'].valid = false;
                    state.errors['email_address'].message = state.errors['email_address'].messages.invalid;
                }
            }
        },
        VALIDATE_APPLICATION_PHONE_NUMBER(state, [key, value]) {
            var phone_number = value,
                regex = /^(\(?(0|\+44)[1-9]{1}\d{1,4}?\)?\s?\d{3,4}\s?\d{3,4})$/i,
                mobile_regex = /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/g;

            if (state.errors['phone_number'].required && (phone_number === '' || phone_number === null)) {
                state.errors['phone_number'].valid = false;
                state.errors['phone_number'].message = state.errors['phone_number'].messages.empty;
            } else {
                phone_number = phone_number.replace(/\s/g, "");
                if (phone_number.length < 10 || phone_number.length > 11) {
                    state.errors['phone_number'].valid = false;
                    state.errors['phone_number'].message = state.errors['phone_number'].messages.invalid;
                } else if (['0', '4', '6', '9'].includes(phone_number.charAt(1))) {
                    state.errors['phone_number'].valid = false;
                    state.errors['phone_number'].message = state.errors['phone_number'].messages.invalid;
                } else if (regex.test(phone_number) || mobile_regex.test(phone_number)) {
                    state.errors['phone_number'].valid = true;
                } else if (phone_number !== null) {
                    state.errors['phone_number'].valid = false;
                    state.errors['phone_number'].message = state.errors['phone_number'].messages.invalid;
                }
            }
        },
        VALIDATE_APPLICATION_POSTCODE( state, [ key, value ] ) {
            var postcode = value,
                regex = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i;

            if( state.errors['postcode'].required && ( postcode === '' || postcode === null ) ){
                state.errors['postcode'].valid = false;
                state.errors['postcode'].message = state.errors['postcode'].messages.empty;
            } else {
                postcode = postcode.replace(/\s/g, "");
                if( regex.test(postcode) ) {
                    state.errors['postcode'].valid = true;
                    state.errors['postcode'].message = '';
                } else if( postcode !== null ) {
                    state.errors['postcode'].valid = false;
                    state.errors['postcode'].message = state.errors['postcode'].messages.invalid;
                }
            }
        },

    },
    actions: {
        restartApplication(context) {
            context.commit('RESTART_APPLICATION');
            window.history.pushState({}, document.title, '/');
        },
        updateApplication(context, [key, value]) {
            context.commit('UPDATE_APPLICATION', [key, value]);
        },
        updateCoverLevel(context, cover_level) {
            context.commit('UPDATE_COVER_LEVEL', cover_level);
        },
        updatePaymentType(context, payment_type) {
            context.commit('UPDATE_PAYMENT_TYPE', payment_type);
        },
        updateStage(context, [stage, can_view]) {
            context.commit('UPDATE_STAGE', [stage, can_view]);
        },
        updatePrices(context, prices) {
            context.commit('UPDATE_PRICES', prices);
        },
        // Adult Actions
        addAdultApplicant(context, value) {
            context.commit('ADD_ADULT_APPLICANT', value);
        },
        updateAdultApplicant(context, [key, value]) {
            context.commit('UPDATE_ADULT_APPLICANT', [key, value]);
        },
        removeAdultApplicant(context, adult_id) {
            context.commit('REMOVE_ADULT_APPLICANT', adult_id);
        },

        // Child Actions
        addChildApplicant(context, value) {
            context.commit('ADD_CHILD_APPLICANT', value);
        },
        updateChildApplicant(context, [key, value]) {
            context.commit('UPDATE_CHILD_APPLICANT', [key, value]);
        },
        removeChildApplicant(context, child_id) {
            context.commit('REMOVE_CHILD_APPLICANT', child_id);
        },

        // Validation Actions
        validateApplication(context, [key, value]) {
            context.commit('VALIDATE_APPLICATION', [key, value]);
        },
        validateApplicationConfirmation(context, value) {
            context.commit('VALIDATE_APPLICATION_CONFIRMATION', value);
        },
        validateApplicationDOB(context, [key, value]) {
            context.commit('VALIDATE_APPLICATION_DOB', [key, value]);
        },
        validateApplicationEmailAddress(context, [key, value]) {
            context.commit('VALIDATE_APPLICATION_EMAIL_ADDRESS', [key, value]);
        },
        validateApplicationPhoneNumber(context, [key, value]) {
            context.commit('VALIDATE_APPLICATION_PHONE_NUMBER', [key, value]);
        },
        validateApplicationPostcode(context, [key, value]) {
            context.commit('VALIDATE_APPLICATION_POSTCODE', [key, value]);
        },
        validateApplicationEmailAddress(context, [key, value]) {
            context.commit('VALIDATE_APPLICATION_EMAIL_ADDRESS', [key, value]);
        },
    },
    plugins: [vuexSessionStorage.plugin]
});

export default store;